<template>
  <div class="page">
    <div>
    <div id="i_can_do">
      <div class='left'>你还在用<span class="zi4"> 肉眼 </span>抓知了、找松茸吗？</div>
      <div class='right'>
        <div>我们可以用<span class="zi5"> AI图像识别技术 </span>帮你找，</div>
        <div>发现目标<span class="zi3"> 自动提醒 </span>您~</div>
      </div>
    </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">抓 知了龟</p>
        <div class="img">
          <img data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_1.png'>
        </div>
        <div class="desc">
          <p data-aos="fade-up">发现知了 语音提醒您~</p>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">找 松茸</p>
        <div class="img">
          <img data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_2.png'>
        </div>
         <div class="desc">
          <p data-aos="fade-up">发现松茸 文字提醒您~</p>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">挖 虫草</p>
         <div class="img">
          <img data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_3.png'>
        </div>
        <div class="desc">
          <p data-aos="fade-up">发现虫草 手机震动您~</p>
        </div>
      </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">抓 蝈蝈</p>
        <div class="img">
          <img class="shadow" data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_4.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">抓 蚂蚱</p>
        <div class="img">
          <img class="shadow" data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_5.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">抓 蛐蛐</p>
        <div class="img">
          <img class="shadow" data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_6.png'>
        </div>
      </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">找 人参</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_7.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">找 灵芝</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_8.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">找 蘑菇</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_9.png'>
        </div>
      </div>
    </div>
    <div class="c">
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">识别农田野草</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_10.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">清点瓜果数量</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_11.png'>
        </div>
      </div>
      <div class="one_about">
        <p class="blue_title"
           data-aos="slide-up">发现入侵物种</p>
        <div class="img">
          <img class="shadow"
               data-aos="zoom-up"
               src='https://www.luckysee.cn/static/img/example/example_12.png'>
        </div>
      </div>
    </div>
    <div id='to_login'
         data-aos="zoom-in">
      <span>去应用商店下载 乐见虫草 APP</span>
    </div>
    <div id='floor'>
      <span>泰安道听途想网络科技有限公司</span>
      <span>微信:corw770357</span>
      <a target="_blank"
         href="https://www.luckysee.cn/agreement"><span>用户协议</span></a>
      <a target="_blank"
         href="https://beian.miit.gov.cn/#/Integrated/index"><span>鲁ICP备20026180号-6</span></a>
      <div style="display:inline-block; vertical-align:middle;">
        <img style="width:0.15rem;high:0.15rem; margin-right:0.01rem;" src="@/assets/img/gongan.png"  alt="">
        <span>37092102000129</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Utils from '../../utils/utils'
export default {
  name: 'index_pc',
  data () {
    return {
    }
  },
  mounted () {
    Utils.is_ios()
    // this.get_index()
  },
  methods: {
    get_index () {
      axios.post('/index_unlogin/', { type: 'pc', sid: this.$route.query.sid, qid: this.$route.query.qid })
        .then(res => {
          // console.log(res)
          if (res.data.code !== 200) {
            Utils.alert({ content: res.data.msg })
            return
          }
          console.log(222)
        })
    }
  }
}
</script>

<style scoped>
/* .page{
  background-color:  #212b36;
} */
#i_can_do{
  padding: 0.3rem 0.1rem 0.2rem 0.1rem;
  margin: 0rem 1rem  0.2rem 1rem;
  background: rgba(37, 130, 173, 0.12);
  font-size: 0.2rem;
}
#i_can_do .left,#i_can_do .right{
  display: inline-block;
  vertical-align: middle;
}
#i_can_do .left{
  width: 3.6rem;
}
#i_can_do .right{
  margin-top: 0.06rem;
  width: 3.6rem;
}
#i_can_do .right div{
  text-align: left;
}
.c {
  width: 8.4rem;
  /* height: 6rem; */
  margin: 0rem auto 0.3rem auto;
}
.one_about {
  display: inline-block;
  text-align: center;
  padding: 0rem 0.05rem;
  margin: 0rem 0.1rem;
  width: 2.5rem;
  /* background: orange; */
}
.blue_title {
  width: 2rem;
  line-height: 0.01rem;
  padding: 0.13rem 0rem;
  margin: 0.72rem auto 0.1rem auto;
  text-align: center;
  display: block;
  background: rgba(37, 130, 173, 0.12);
  border-radius: 2rem;
  color: #fefefe;
  font-size: 0.12rem;
}

.one_about .img {
  /* height: 6rem; */
  height: 1.6rem;
  margin: 0.4rem 0rem 0.2rem 0rem;
}
.one_about .img img {
  width: 2rem;
}
.shadow {
  box-shadow: 0.01rem 0.02rem 0.02rem rgba(37, 130, 173, 0.1);
}

/* #2B3953  #30415D */
.one_about .desc {
  margin: 0.1rem 0rem 0.2rem 0rem;
  /* text-align: left; */
}
.one_about .desc p {
  line-height: 0.24rem;
  color: #fefefe;
  font-size: 0.1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
#to_login {
  /* position: fixed;
  bottom: 66px;
  right: 0.8rem; */
  width: 8.4rem;
  /* height: 1rem; */
  text-align: center;
  margin: 0.5rem auto;
  /* background: #fdfdfd; */
}

#to_login span {
  display: inline-block;
  padding: 28px 0px 27px 0px;
  line-height: 1px;
  width: 600px;
  background: rgba(37, 130, 173, 0.12);
  border-radius: 100px;
  color: #fefefe;
  font-size: 24px;
}
#floor {
  /* position: fixed;
  bottom: 10px; */
  /* right: 0.8rem; */
  background: #2b3953;
  height: 0.5rem;
  line-height: 0.5rem;
  margin: 0.5rem auto 0rem auto;
  padding: 0.2rem auto 0rem auto;
}
#floor span {
  /* float: right; */
  display: inline-block;
  margin: 0rem 0.1rem;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.13rem;
}
</style>
